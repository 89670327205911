<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/user">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Add Customer</h4>
          </div>
        </div>

        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
            <b-form v-on:submit.prevent="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Username:"
                label-for="input-1"
              >
                <b-form-input
                  autocomplete="off"
                  v-model="username"
                  :maxlength="20"
                  required
                  placeholder="Enter UserName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Email:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  autocomplete="off"
                  v-model="email"
                  @keyup="isEmailValid"
                  :maxlength="60"
                  required
                  placeholder="Enter Email"
                ></b-form-input>
              </b-form-group>
              <div
                v-show="!this.emailValidation"
                class="alert alert-danger"
                role="alert"
              >
                Invalid Email
              </div>

              <b-form-group
                id="input-group-2"
                label="Password:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  autocomplete="off"
                  type="password"
                  v-model="password"
                  required
                  placeholder="Enter Password"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Mobile Number:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="mobile"
                  type="number"
                  required
                  placeholder="Enter Mobile Number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Postal Code:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="zipcode"
                  @keyup="isZipCodeValid"
                  type="text"
                  required
                  placeholder="Enter post code"
                ></b-form-input>
              </b-form-group>

              <div
                v-show="!this.zipcodeValidation"
                class="alert alert-danger"
                role="alert"
              >
                Invalid Zip Code
              </div>

              <b-form-group
                id="input-group-2"
                label="Address:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="address"
                  type="text"
                  required
                  placeholder="Enter Address"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" ref="save" variant="primary"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

import DisableAutocomplete from "vue-disable-autocomplete";
Vue.use(DisableAutocomplete);

export default {
  data() {
    return {
      email: "",
      emailValidation: true,
      zipcodeValidation: true,
      mobile: "",
      alertText: "",
      alert: false,
      username: "",
      password: "",
      address: "",
      zipcode: "",
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },

  mounted() {
    document.getElementById("#username").value = "";
  },

  methods: {
    isEmailValid: function isEmailValid() {
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.emailValidation = true;
      } else {
        this.emailValidation = false;
      }
      return responce;
    },

    isZipCodeValid: function isZipCodeValid() {
      var regex1 = /^([A-Z][A-Ha-hJ-Yj-y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|[Gg][Ii][Rr] ?0[A]{2})$/;
      var responcend = regex1.test(this.zipcode);
      if (responcend) {
        this.zipcodeValidation = true;
      } else {
        this.zipcodeValidation = false;
      }
      return responcend;
    },
    onSubmit(evt) {
      if (!this.emailValidation || !this.zipcodeValidation) {
        evt.preventDefault();
        return false;
      }

      try {
        const data = {
          first_name: "",
          last_name: "",
          username: this.username,
          email: this.email,
          password: this.password,
          mobile: this.mobile,
          zipcode: this.zipcode,
          address: this.address,
        };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/register-user", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            localStorage.setItem(
              "alertmessage",
              JSON.stringify("Record has been added successfully")
            );
            this.$router.push({ name: "user" });
          }
          if (response.data.code == 422) {
            this.alert = true;
            this.alertText = response.data.message;
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.alert {
  padding: 0.3rem 0.5rem;
}
</style>
